<div class=" bd-back-color y3-padding-bottom-8">
  <div  id="bookingDetail" class="guest-view-desktop-section-container padding-right-16 padding-left-16 padding-top-16 y3-margin-bottom-16 ">

    <div style=" border-bottom: 1px gainsboro solid; padding: 8px; margin-bottom: 16px;">
      <div *ngIf="canShowIglooHome() || canShowTheKeys() || isSwiklyConfigured() || canShowSmartLock() || (property.needPreCheck && booking) || (importantGuides && importantGuides.length > 0)"  class="y3-margin-bottom-24 y3-margin-top-16">

        <mat-icon class="material-symbols-outlined y3-steph-title-dot">warning</mat-icon>
        <div class="y3-steph-title">{{'guest-view-booking-details.important-info' | translate}}<span class="y3-steph-title-dot">.</span></div>

      </div>

      <div class="partner-section margin-bottom-24" *ngIf="canShowIglooHome() || canShowTheKeys() || isSwiklyConfigured() || canShowSmartLock() || (property.needPreCheck && booking) || (importantGuides && importantGuides.length > 0)">

        <perfect-scrollbar class="y3-flex-row " [config]="{useBothWheelAxes: true, suppressScrollX: false, suppressScrollY: false}">
          <div class="y3-flex-row y3-flex-align-center y3-margin-bottom-16" #partnerContainer>

            <section 
            id="_____SECTION____LOGEMENT_IS_READY_OR_NOT" 
            *ngIf="property.needPreCheck && booking && isExpectedCheckinLocalTime()">
            
            <div class="y3-steph-partner-block y3-margin-right-8">
              <div *ngIf="isPropertyReady()" class="y3-steph-important-title">
                {{ 'guest-view-booking-details.logement-ready' | translate }}
              </div>
              <div *ngIf="!isPropertyReady()" class="y3-steph-important-title">
                {{ 'guest-view-booking-details.logement-not-ready' | translate }}
              </div>
              <div class="y3-full-width y3-flex-row y3-flex-align-center y3-flex-justify-content-center y3-margin-top-12">
                <mat-icon *ngIf="isPropertyReady()" class="material-symbols-outlined y3-steph-title-green-dot">check_circle</mat-icon>
                <mat-icon *ngIf="!isPropertyReady()" class="material-symbols-outlined y3-steph-title-dot">cancel</mat-icon>
              </div>
            </div>
          </section>
          


          <section id="_____SECTION____CODE__AVAILABLE" *ngIf="booking != null && isCautionDeposed() && canShowCodeAvailability() && !isSmartLockCodeAllowToShow() ">
            <div class="y3-steph-partner-block y3-margin-right-8 y3-flex-align-center">
              <div class="y3-full-width y3-steph-important-title">{{'guest-detail-booking.smartlock-available-day'  | translate}}</div>
              <div class="partner-name  y3-margin-top-8 ">{{getSmartLockAvailableDay() | moment: translateService.currentLang  | titlecase}}</div>
              <div  class="y3-full-width y3-flex-row y3-flex-align-center y3-flex-justify-content-center y3-margin-top-12">
                <mat-icon class="material-symbols-outlined y3-steph-title-dot">cancel</mat-icon>
              </div>
            </div>
          </section>

          <section id="_____SECTION____DOOR_LOCK_CODE__AVAILABLE" *ngIf="booking != null && isSmartLockCodeAllowToShow() && booking.doorLockCode">
            <div class="y3-steph-partner-block y3-margin-right-8 y3-flex-align-center">
              <div class="y3-steph-important-title y3-margin-top-8">{{'guest-detail-booking.smartlock-doorLockCode'  | translate}}</div>
              <div class="partner-name y3-margin-top-8 y3-bold">{{booking.doorLockCode}}</div>
            </div>
          </section>


          <section id="_____SECTION____IGLOOHOME_CODE__AVAILABLE" *ngIf="canShowIglooHome()  && isCautionDeposed() && isSmartLockCodeAllowToShow()">

            <div class="y3-steph-partner-block y3-margin-right-8 y3-flex-align-center">
              <div class="y3-steph-important-title y3-margin-top-8">{{'guest-detail-booking.smartlock-doorLockCode'  | translate}}</div>
              <div *ngIf="(booking.iglooHomeAccess ? booking.iglooHomeAccess.code : booking.iglooHomeLockCode) as code" class="y3-margin-top-18 " style="font-size: 22px;" (click)="clipBoardCopy(code)">{{utilService.formatIgloohomeCode(code)}}🔓</div>
            </div>
          </section>

            <section id="_____SECTION____KEYNEST_CODE__AVAILABLE" *ngIf="booking != null && booking?.keynestAccess  && isCautionDeposed() && isSmartLockCodeAllowToShow()">
              <div class="y3-steph-partner-block y3-margin-right-8 y3-flex-align-center">
                <img src="assets/images/partners/Keynest-icon.png" alt="keynest" style="width: 60px; height: 60px;">
                <div class="y3-steph-important-title y3-margin-top-8">{{'Keynest Collection Code'  | translate}}</div>
                <div *ngIf="booking.keynestAccess?.CollectionCode" class="y3-margin-top-18 " style="font-size: 22px;" (click)="clipBoardCopy(booking.keynestAccess?.CollectionCode)">{{booking.keynestAccess?.CollectionCode}}</div>
                <div class="y3-XX-small y3-margin-top-4"> <a *ngIf="booking?.keynestAccess?.CollectionCodeMagicLink" href="{{booking?.keynestAccess?.CollectionCodeMagicLink}}" class="y3-Small y3-underline txt-color-dimgray">More information</a>
                </div>
              </div>
            </section>

            <section id="_____SECTION____NUKI_CODE__AVAILABLE" *ngIf="canShowSmartLock() && isCautionDeposed() && isSmartLockCodeAllowToShow() ">
              <div style="display: flex; flex-flow: row">
                <div *ngFor="let lock of booking?.smartLockAccessList">
                  <div class="y3-steph-partner-block y3-margin-right-8 y3-flex-align-center" *ngIf="displayDuringStay(lock)">
                    <div class="y3-steph-important-title " style="max-width: 175px; white-space: nowrap; text-overflow: ellipsis; overflow: hidden;">{{(lock.smartLockYaagoDisplayName)? lock.smartLockYaagoDisplayName : lock.smartLockName}}</div>
                    <div *ngIf="(lock.smartLockCode ? lock.smartLockCode : '')" class="y3-margin-top-12 y3-roboto" style="font-size: 22px;">{{(lock.smartLockCode ? lock.smartLockCode : '')}}</div>

                    <div class="margin-top-8">
                      <div>
                        <app-button *ngIf="lock.urlToOpen && displayDuringStay(lock)" [type]="'steph-button-blue'" [text]="'guest-detail-booking.smartlock-link' | translate" (debounceClick)="unlockSmartLock(lock.urlToOpen, 'open', lock)"></app-button>
                      </div>
                      <div class="margin-top-4">
                        <app-button *ngIf="lock.urlToClose && displayDuringStay(lock)" [type]="'steph-button-blue'" [text]="'guest-detail-booking.smartlock-link-close' | translate" (debounceClick)="unlockSmartLock(lock.urlToClose, 'close', lock)"></app-button>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </section>


            <section id="_____SECTION____THE_KEYS_CODE__AVAILABLE" *ngIf="canShowTheKeys() && isCautionDeposed() && isSmartLockCodeAllowToShow()">
              <div class="y3-steph-partner-block y3-margin-right-8 y3-flex-align-center">
                <div class="y3-steph-important-title y3-margin-top-8">{{'guest-detail-booking.smartlock-doorLockCode'  | translate}}</div>
                <div class="y3-margin-top-12 y3-roboto" style="font-size: 22px;" >{{utilService.getTheKeysCode(booking)}}</div>
                <a class="y3-margin-top-16 y3-steph-button" *ngIf="utilService.getTheKeysLink(booking) != ''" href="{{utilService.getTheKeysLink(booking)}}" target="_blank">{{'guest-detail-booking.smartlock-link' | translate}}</a>
              </div>
            </section>


            <section id="_____SECTION____SWIKLY" class="y3-flex-row y3-flex-align-center">
          <div *ngIf="isSwiklyConfigured()"
               class="y3-steph-partner-block y3-clickable y3-margin-right-8 y3-flex-align-center  ">

              <div class="y3-steph-important-title" >Swikly</div>

              <div>
                <img class="swikly-icon" src="assets/icon/partner/swikly.svg" alt="swikly">
              </div>
              <div class="swikly-status y3-Small margin-top-4">
                    <span style="text-align: center">
                      {{(isCautionDeposed() ? 'guest-view-booking-details.partners.swikly-alredy-deposit' : 'guest-view-booking-details.partners.swikly-pending') | translate}}
                    </span>
                <img src="{{isCautionDeposed() ? '/assets/icon/valid.svg': '/assets/icon/not-valid.svg'}}" alt="status">
              </div>
              <a class="margin-top-16 swikly-btn" *ngIf="!isCautionDeposed() && isCautionAllowToShow()" href="{{booking?.swiklyInfo?.swiklyAcceptUrl}}" target="_blank">{{'guest-view-booking-details.partners.deposit-caution' | translate}}</a>
              <span *ngIf="isCautionDeposed()" class="margin-top-8 roboto X-small txt-color-scorpion">{{booking.swiklyInfo?.swiklyDepositAmount?.amount | currency : booking.swiklyInfo?.swiklyDepositAmount?.currency}}</span>
          </div>
            </section>


          <section id="_____SECTION____IMPORTANT_GUIDE" class="y3-flex-row y3-flex-align-center">
            <div *ngFor="let guide of importantGuides" >
              <div class="y3-steph-partner-block y3-clickable y3-margin-right-8" (click)="showGuide(guide)" *ngIf="needToShowGuide(guide)">
                <div class="y3-steph-partner-inside-block2 ">
                  <div class="y3-steph-important-title" style="height: 50px;"><span *ngIf="guide.category==='wifi'">{{guide.category.toUpperCase() + ' : ' }}</span>{{guide.translations[0].title}}</div>
                  <div class="y3-flex-row y3-flex-justify-content-center y3-margin-top-16">
                    <img class="y3-steph-icon-guide" src="{{getGuideCover(guide)}}" alt="guide">
                  </div>
                </div>
              </div>
            </div>
          </section>

        </div>
        </perfect-scrollbar>
      </div>
    </div>

    <app-g4-booking-details id="details" #details [booking]="booking" [property]="property" [corporate]="corporate"></app-g4-booking-details>

  </div>

  <div class="details-dialog-container" *ngIf="currentGuide && showGuideDetails">

    <div class="details-container" >
      <img src="assets/icon/close-round.svg" alt="icon" class="closee clickable" (click)="closeGuide()">

      <app-guide-details [booking]="booking" [property]="property" [guide]="currentGuide"></app-guide-details>
    </div>

  </div>
</div>
