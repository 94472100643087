import {Component, HostListener, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {CoHostInCharge, PropertyForGuest} from '../../../models/guestview/PropertyForGuest';
import {BookingForGuest, TeammateInCharge} from '../../../models/guestview/BookingForGuest';
import {UtilsService} from '../../../core/services/utils.service';
import * as moment from 'moment';
import {PersonForGuest} from '../../../models/guestview/PersonForGuest';
import {TranslateService} from '@ngx-translate/core';
import {GuestService} from '../../../core/services/guest.service';
import {ClipboardService} from 'ngx-clipboard';
import {DomSanitizer} from '@angular/platform-browser';
import {InternalPropertyService} from '../../../core/services/internal.property.service';
import {CategoryGuide, DocType, Guide} from "../../../models/Guide";
import {Router} from '@angular/router';
import {Corporate} from "../../../models/guestview/Corporate";
import {SmartLockAccess} from "../../../models/guestview/partners/SmartLockAccess";
import {Poi} from '../../../models/Poi';
import {Property} from '../../../models/Property';
import {ApiService} from '../../../core/http/api.service';
import {SnackbarMessage} from '../../../shared/components/shared-snackbar/models/snackbar-message';
import {SnackbarService} from '../../../core/services/SnackbarService';
import {SharedSnackbarService} from '../../../shared/components/shared-snackbar/services/shared-snackbar.service';
import {GuestGuideDetailsComponent} from '../guest-guides/guest-guide-details/guest-guide-details.component';
import {MatDialog} from '@angular/material';
import {Overlay} from '@angular/cdk/overlay';

@Component({
  selector: 'app-guest-booking-details',
  templateUrl: './guest-booking-details.component.html',
  styleUrls: ['./guest-booking-details.component.scss']
})
export class GuestBookingDetailsComponent implements OnInit, OnChanges {
  styledMapType;
  screenWidth: number;
  activePartner: string;
  fulldescription: any = false;
  mainPropertyPhoto: string = null;
  public localCoHostInCharge: CoHostInCharge = null;
  localShowMeAsHost = true;
  mapProp: any;
  @Input() property: PropertyForGuest;
  @Input() booking: BookingForGuest;
  @Input() corporate: Corporate;
  @ViewChild('gMap', null) gMapElement: any;
  @ViewChild('mytemplate', null) mytmpl: any;
  guestToDisplay: string[] = [];
  nbGuest = 0;
  public map: any;
  public markers = [];
  synthesis = window.speechSynthesis;
  urlsAudioForDescription: any[] = [];
  lat: number;
  long: number;
  partnerToDisplay = 0;
  public iconMarkers: any;
  currentGuide: Guide;
  showGuideDetails = false;
  importantGuides: Guide[] = [];


  // NEW RECOMMENDATION MAP
  centerOfTheMap: Poi;
  propertiesForMap: Property[] = [];
  propertyPoi: Poi;
  whereIamPoi: Poi;
  platformName: string = "";
  propertyReference: string = "";
  showZoomControl = true;
  showFilterReco = true;
  showOwner = true;
  showRecoYaago = false;
  showCommunityReco = false;
  showRecoInfluencer = false;
  recapVersion = 'v1';
  language = 'fr';

  constructor(public utilService: UtilsService,
              public translateService: TranslateService,
              public router: Router,
              public guestService: GuestService, public clipBoardSrv: ClipboardService, public sanitizer: DomSanitizer, public propertyService: InternalPropertyService,
              public apiService: ApiService,
              protected dialog: MatDialog,
              public snackbar: SharedSnackbarService) {
    this.getScreenWidth();
  }

  ngOnInit() {

    this.guestService.getCorporate().subscribe( res => {

      this.corporate = res;
      this.localShowMeAsHost = this.corporate.showMeAsHost;
      if (this.corporate && !this.corporate.onlyMineRecommendations) {
        this.showRecoYaago = true;
        this.showRecoInfluencer = true;
        this.showCommunityReco = true;
        // console.log("RECOOOOOO true", this.corporate);
      } else if (this.corporate && this.corporate.onlyMineRecommendations) {
        this.showRecoYaago = false;
        this.showCommunityReco = false;
        this.showRecoInfluencer = false;
        //console.log("RECOOOOOO false");
      }
      if (this.corporate && this.corporate.coHostInCharge) {
        this.localCoHostInCharge = this.corporate.coHostInCharge;
      } else if (this.property && this.property.coHostInCharge) {
        this.localCoHostInCharge = this.property.coHostInCharge;
      } else {
        this.localCoHostInCharge = null;
        this.localShowMeAsHost = !this.corporate.showMeAsHost;
      }

    });

  }

  ngOnChanges(changes: SimpleChanges): void {


    if (this.booking) {
      this.nbGuest = this.booking.adults + this.booking.children + this.booking.babies;
      if (this.nbGuest === 0) {
        this.nbGuest = 1;
      }
      for (let i = 0; i < (this.nbGuest - 2); i++) {
        this.guestToDisplay.push('new');
      }

    }

    if (this.corporate) {
      if (this.corporate && !this.corporate.onlyMineRecommendations) {
        this.showRecoYaago = true;
        this.showRecoInfluencer = true;
        this.showCommunityReco = true;
        //console.log("RECOOOOOO true", this.corporate);
      } else if (this.corporate && this.corporate.onlyMineRecommendations) {
        this.showRecoYaago = false;
        this.showCommunityReco = false;
        this.showRecoInfluencer = false;
        //console.log("RECOOOOOO false");
      }

      if (this.corporate.coHostInCharge) {
        this.localCoHostInCharge = this.corporate.coHostInCharge;
        this.localShowMeAsHost = this.corporate.showMeAsHost;
      } else if (this.property && this.property.coHostInCharge) {
        this.localCoHostInCharge = this.property.coHostInCharge;
        this.localShowMeAsHost = this.property.showMeAsHost;
      } else {
        this.localCoHostInCharge = null;
        this.localShowMeAsHost = false;
      }
    }

    if (changes.property && this.property) {

      if (this.property.poi) {
        this.centerOfTheMap = new Poi();
        this.centerOfTheMap.x = this.property.poi.x;
        this.centerOfTheMap.y = this.property.poi.y

        this.propertyPoi = this.property.poi;
        // const defaultBounds = new google.maps.LatLngBounds(new google.maps.LatLng(this.property.poi.y, this.property.poi.x));
      }

      if (this.propertyService.getMainPhotoUrl()) {
        this.mainPropertyPhoto = this.propertyService.getMainPhotoUrl().url.replace('aki_policy=medium', '');
      } else {
        this.mainPropertyPhoto = null;
      }

      if (this.property.guides && this.property.guides.length > 0) {
        this.importantGuides = this.property.guides.filter(item => item.showAsImportantInfo);
        this.importantGuides.sort((a, b) => a.showAsImportantInfoOrder - b.showAsImportantInfoOrder);

      }

      // get the AudioFile For Description
      /*this.urlsAudioForDescription = googleTTS.getAllAudioUrls(this.property.description, {
        lang: this.translateService.currentLang,
        slow: false,
        host: 'https://translate.google.com',
      });
      console.log('urlof the audio to play', this.urlsAudioForDescription);*/
    }
  }

  pause() {
    this.synthesis.pause();
  }

  resume() {
    this.synthesis.resume();
  }

  playDescription() {
    this.synthesis.cancel();
    const localLangue = this.translateService.currentLang;
    this.synthesis.getVoices().forEach(v => {

    })
    var voice = this.synthesis.getVoices().filter(function (voice) {
      return voice.lang.indexOf(localLangue) > 0;
    })[0];

    // Create an utterance object
    var utterance = new SpeechSynthesisUtterance(this.property.description);

    // Set utterance properties
    utterance.voice = voice;
    utterance.lang = localLangue;
    utterance.pitch = 1;
    utterance.rate = 1;
    utterance.volume = 0.8;




    // Speak the utterance
    this.synthesis.speak(utterance);


    /* const client = new textToSpeech.TextToSpeechClient();
     const [response] = await client.synthesizeSpeech(request);
     // Write the binary audio content to a local file
     const writeFile = util.promisify(fs.writeFile);
     await writeFile('output.mp3', response.audioContent, 'binary');
     console.log('Audio content written to file: output.mp3');

       var song1 = new Audio();
       var src1 = document.createElement("source");
       src1.type = "audio/mpeg";
       src1.src = this.urlsAudioForDescription[0].url;
       song1.appendChild(src1);
       song1.play();*/
  }

  readmore() {
    this.fulldescription = !this.fulldescription;
  }


  getDay(date: string) {
    if (date) {
      return moment(date).format('D');
    } else {
      return '';
    }
  }

  getMonth(date: string) {
    if (date) {
      return moment(date).locale(this.translateService.currentLang).format('MMM');
    } else {
      return '';
    }
  }

  getTime(date: string, defaultDate: string) {
    if (date) {
      return moment(date, 'hh:mm:ss').format('HH:mm').replace(':', 'h');
    } else if (defaultDate) {
      return (moment(defaultDate, 'hh:mm:ss').format('HH:mm')).replace(':', 'h');
    } else {
      return this.translateService.instant('guest-detail-booking.set-time');
    }
  }



  getPictureOfMyHost(): string {
    let ret = '';
    if (this.property) {
      if (this.property.host && this.property.host.pictureUrl) {
        ret = this.property.host.pictureUrl;
      }
    }
    return ret;
  }

  whatsapp(use: PersonForGuest) {
    document.location.href = use.whatsAppLink;
  }

  call(use: PersonForGuest) {
    document.location.href = 'tel:' + use.phone;
  }

  callTeamInCharge(use: TeammateInCharge) {
    document.location.href = 'tel:' + use.phoneNumber;
  }

  callTeammate(use: TeammateInCharge | CoHostInCharge) {
    document.location.href = 'tel:' + use.phoneNumber;
  }

  emailPerson(use: PersonForGuest | TeammateInCharge | CoHostInCharge) {
    document.location.href = 'mailto:' + use.email;
  }

  emailPersonTeammate(use: TeammateInCharge | CoHostInCharge) {
    document.location.href = 'mailto:' + use.email;
  }

  getLanguage(languageCode: string) {
    return UtilsService.getLanguageFlag(languageCode);
  }


  isDesktopMode(): boolean {
    return this.utilService.isDesktop(this.screenWidth);
  }

  @HostListener('window:resize', ['$event'])
  getScreenWidth(event?) {
    this.screenWidth = window.innerWidth;
  }

  getImportantInfoCount(): number {
    let infosCount = 0;
    if (this.canShowIglooHome()) {
      infosCount += 1;
      this.activePartner = 'igloohome';
    }
    if (this.canShowTheKeys()) {
      infosCount += 1;
      this.activePartner = 'thekeys';
    }
    if (this.isSwiklyConfigured()) {
      if (this.activePartner == null) {
        this.activePartner = 'swikly';
      }
      infosCount += 1;
    }
    if (this.property.needPreCheck && this.booking) {
      infosCount += 1;
    }

    if (this.importantGuides && this.importantGuides.length > 0) {
      infosCount += this.importantGuides.length;
    }

    return infosCount;
  }

  deposeCaution() {
    console.log('cautio deposit');
  }

  counter(type: 'travelers' | 'beds' | 'baths' | 'doublebeds' | 'singlebeds' | 'sofabeds') {
    switch (type) {
      case 'travelers':
        const travelersCount = this.booking.adults + this.booking.children + this.booking.babies;
        return travelersCount > 0 ? travelersCount : (this.booking.guests ? this.booking.guests.length : 0);

      case 'beds':
        return this.property.doubleBeds + this.property.singleBeds + this.property.sofaBeds + this.property.babyBeds;
      case 'doublebeds':
        return this.property.doubleBeds;
      case 'singlebeds':
        return this.property.singleBeds;
      case 'sofabeds':
        return this.property.sofaBeds;
      case 'baths':
        return this.property.bathrooms;
    }
  }

  isSwiklyConfigured() {
    return this.booking && this.booking.swiklyInfo && this.booking.swiklyInfo.swiklyAcceptUrl;
  }

  isIgloohomeConfigured(): boolean {
    return this.property.iglooHomeDisplayWelcomeBooklet && this.booking && !!(this.booking.iglooHomeAccess ? this.booking.iglooHomeAccess.code : this.booking.iglooHomeLockCode);
  }

  isTheKeysConfigured(): boolean {
    return this.property.smartLockDisplayWelcomeBooklet && this.booking && this.booking.theKeysAccesses && this.booking.theKeysAccesses.length > 0;
  }

  needToShowGuide(guide: Guide) {
    if (guide.partnerName != null && guide.partnerName !== '' && guide.partnerType != null && guide.partnerType !== '') {
      if (guide.partnerName === 'swikly') {
        return this.isSwiklyConfigured() && !this.booking.swiklyInfo.swiklyDepositAcceptedDate;
      } else if (guide.partnerName === 'the-keys') {
        return this.isTheKeysConfigured();
      } else if (guide.partnerName === 'igloohome') {
        return this.isIgloohomeConfigured();
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  isCautionDeposed(): boolean {
    let ret = true;
    if (this.isSwiklyConfigured() && this.booking.swiklyInfo.swiklyDepositAcceptedDate == null) {
      ret = false;
    } else if (this.isSwiklyConfigured() && this.booking.swiklyInfo.swiklyDepositAcceptedDate != null) {
      ret = true;
    } else if (!this.isSwiklyConfigured() && this.booking.depositInfo != null) {
      ret = this.booking.depositInfo.depositDone;
    }
    return ret;
  }

  isCautionAllowToShow(): boolean {
    // console.log(this.booking.startDate);
    // console.log(moment(this.booking.startDate).add(-5, 'days').format('YYY/MM/DD'));
    return moment(this.booking.startDate).add(-1 * this.getNbDaysForDepositShow(), 'days').isBefore(moment.now());

  }

  isSmartLockCodeAllowToShow(): boolean {
    // console.log(this.booking.startDate);
    // console.log(moment(this.booking.startDate).add(-5, 'days').format('YYY/MM/DD'));
    if (this.booking) {
      return moment(this.booking.startDate).add(-1 * this.getNbDaysForSmartLockCodeShow(), 'days').isBefore(moment.now());
    } else {
      return false;
    }
  }

  getSmartLockAvailableDay() {
    return moment(this.booking.startDate).add(-1 * this.getNbDaysForSmartLockCodeShow(), 'days').toISOString();
  }

  getNbDaysForSmartLockCodeShow(): number {
    return this.property.nbDaysToShowSmartLockCode != null ? this.property.nbDaysToShowSmartLockCode : 3;
  }

  getNbDaysForDepositShow(): number {
    return this.property.nbDaysToShowDepositLink != null ? this.property.nbDaysToShowDepositLink : 5;
  }

  canShowCodeAvailability() {
    return (this.isIgloohomeConfigured() || this.isTheKeysConfigured()) || (this.booking.smartLockAccessList != null && this.booking.smartLockAccessList.length > 0);
  }

  canShowIglooHome() {
    return (this.isIgloohomeConfigured() && (!this.isSwiklyConfigured() || this.isCautionDeposed()));
  }

  canShowTheKeys() {
    return (this.isTheKeysConfigured() && (!this.isSwiklyConfigured() || this.isCautionDeposed()));
  }

  canShowSmartLock() {

    if (this.booking) {
      if (this.property.nbDaysToShowSmartLockCode > 0) {
        return moment(this.booking.startDate).add(-this.property.nbDaysToShowSmartLockCode, 'days').isBefore(moment.now());
      } else {
        return moment(this.booking.startDate).add(-1, 'days').isBefore(moment.now());
      }
    } else {
      return false;
    }

    //return (this.booking != null && this.booking.smartLockAccessList != null && this.booking.smartLockAccessList.length > 0);
  }

  clipBoardCopy(text: string) {
    this.clipBoardSrv.copy(text);
  }

  getFirstLetter(host: PersonForGuest | CoHostInCharge) {
    if (!host) {
      return '';
    }
    if (host.firstName) {
      return host.firstName.substr(0, 1);
    }
    if (host.lastName) {
      return host.lastName.substr(0, 1);
    }
    if (host.email) {
      return host.email.substr(0, 1);
    }
  }

  transform(url): any {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  closeGuide() {
    this.showGuideDetails = false;
  }

  showGuide(guide: Guide) {

    // const scrollStrategy = this.overlay.scrollStrategies.reposition();
    const taskCreationDialog = this.dialog.open(GuestGuideDetailsComponent, {
      height: this.isDesktopMode() ? '90vh' : '90vh',
      width: this.isDesktopMode() ? '75vw': '90vw',
      autoFocus: false,
      data: {
        property: this.property,
        guide: guide
      }
    });

    // this.currentGuide = guide;
    // this.showGuideDetails = true;
  }



  getGuideCover(guide: Guide) {

    if (guide && guide.guidePictureUrl) {
      return guide.guidePictureUrl;
    }

    if (!guide || !guide.documents || guide.documents.length === 0) {
      return this.getPictureFromCategory(guide.category);
    }
    const firstPhoto = guide.documents.find(item => item.type === DocType.IMAGE);
    if (!firstPhoto) {
      return this.getPictureFromCategory(guide.category);
    }

    return firstPhoto.url;
  }

  /*getGuideCover(guide: Guide) {

    if (this.corporate && this.corporate.customGuideCategoriesPictures) {
      if (!this.corporate.bookletSeeGuidePictureNotCategory) {
        const found = this.corporate.customGuideCategoriesPictures.find(
          (item) => guide.category === item.categoryName
        );
        if (found && found.url) {
          return found.url;
        }
      }
    }

    if (!guide || !guide.documents || guide.documents.length === 0) {
      return this.getPictureFromCategory(guide.category);
    }
    const firstPhoto = guide.documents.find(item => item.type === DocType.IMAGE);
    if (!firstPhoto) {
      return this.getPictureFromCategory(guide.category);
    }

    return firstPhoto.url;
  }*/

  getPictureFromCategory(category: string) {
    let image;

    if (!category) {
      return image;
    }
    if (this.corporate && this.corporate.customGuideCategoriesPictures) {
      const found = this.corporate.customGuideCategoriesPictures.find(
        (item) => category === item.categoryName
      );
      if (found && found.url) {
        return found.url;
      }
    }

    switch (category) {
      case CategoryGuide.APPLIANCES:
        image = '../../../../assets/images/list-guides/Appliance/Appliances.png';
        break;
      case CategoryGuide.CHECKIN:
        image = '../../../../assets/images/list-guides/Checkin/checkin.png';
        break;
      case CategoryGuide.CHECKOUT:
        image = '../../../../assets/images/list-guides/Check-out/checkout.png';
        break;
      case CategoryGuide.ORIENTATION:
        image = '../../../../assets/images/list-guides/Direction/dir.png';
        break;
      case CategoryGuide.RULES:
        image = '../../../../assets/images/list-guides/Rules/img@3x.png';
        break;
      case CategoryGuide.SERVICES:
        image = '../../../../assets/images/list-guides/service.png';
        break;
      case CategoryGuide.WIFI:
        image = '../../../../assets/images/list-guides/Wifi/wifi.png';
        break;
      case CategoryGuide.PARKING:
        image = '../../../../assets/images/list-guides/Parking/parking.png';
        break;
      case CategoryGuide.TRASH:
        image = '../../../../assets/images/list-guides/Trash/trash.png';
        break;
      case CategoryGuide.HEATING:
        image = '../../../../assets/images/list-guides/Heating/heater.png';
        break;
      case CategoryGuide.OTHER:
        image = '../../../../assets/images/list-guides/Other/questions.png';
        break;
      default: {
        image = '../../../../assets/images/list-guides/Appliance/Appliances.png';
      }
    }
    return image;
  }
  isPropertyReady() {
    if (!this.booking) {
      return false;
    }
    const checkinTime = moment(this.booking.startDate + ' ' + this.booking.expectedCheckinLocalTime || this.booking.requestedCheckinLocalTime, 'YYYY-MM-DD HH:mm:ss'); 
    console.log('this.booking', this.booking)
    console.log('checkinTime', checkinTime);
    console.log('booking.startDate', this.booking.startDate);
    console.log('booking.expectedCheckinLocalTime', this.booking.expectedCheckinLocalTime);
    console.log('requested checkinLocalTime', this.booking.requestedCheckinLocalTime);
    console.log('needPreCheck', this.property.needPreCheck);
    console.log('preCheckIn', this.booking.preCheckIn);
    console.log('checkinTime', checkinTime);
    return (this.property.needPreCheck && this.booking.preCheckIn != null && this.booking.preCheckIn.checkAt) || moment().isAfter(checkinTime);
  }

  isExpectedCheckinLocalTime(): boolean {
    return !!(this.booking.expectedCheckinLocalTime) || !!(this.booking.requestedCheckinLocalTime);
}

  showInventory() {
    this.router.navigate(['/guest/' + this.booking.id + '/inventory']).then();
  }




  isDuringTheStay(): boolean {
    return moment(this.booking.startDate).add(-1, 'days').isBefore(moment.now()) && moment(this.booking.endDate).add(1, 'days').isAfter(moment.now());
  }

  unlockSmartLock(urlToGet: string, action: string, lock: SmartLockAccess) {
    this.apiService.sendGetUrl(urlToGet).subscribe( res => {
      if (action === 'open') {
        this.snackbar.push(new SnackbarMessage(this.translateService.instant('guest-detail-booking.guest-smartlock-open'), 'info'));
        lock.smartLockState = 'smartlock_open';
      } else {
        lock.smartLockState = 'smartlock_close';
        this.snackbar.push(new SnackbarMessage(this.translateService.instant('guest-detail-booking.guest-smartlock-close'), 'info'));
      }
    }, err => {
      console.log("error smartlock unlock", err);
      this.utilService.handleYaagoErrorCode(err);
    });
  }




}
